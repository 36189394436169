import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { ArrowTop, Chat, Services, Wand } from '@images/icons';

import { NavLink } from './NavLink';

export const MobileNav = () => {
  const location = useLocation();
  const [activeSection, setActiveSection] = useState<string | null>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const moveWizardElement = document.querySelector('#move-wizard');
    const homeTeamElement = document.querySelector('#home-team');
    const myServicesElement = document.querySelector('#my-services');

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (moveWizardElement) {
      observer.observe(moveWizardElement);
    }
    if (homeTeamElement) {
      observer.observe(homeTeamElement);
    }
    if (myServicesElement) {
      observer.observe(myServicesElement);
    }

    return () => {
      if (moveWizardElement) {
        observer.unobserve(moveWizardElement);
      }
      if (homeTeamElement) {
        observer.unobserve(homeTeamElement);
      }
      if (myServicesElement) {
        observer.unobserve(myServicesElement);
      }
    };
  }, []);

  if (location.pathname !== '/') return null;

  return (
    <div
      className={twMerge(
        'fixed -bottom-10 left-0 right-0 z-[6000] transform transition-transform sm:px-3',
        isVisible
          ? 'animate-pop-in translate-y-0 duration-300 ease-out'
          : 'translate-y-full duration-300 ease-in'
      )}
    >
      <nav className="shadow-homeLargeDark mx-auto w-full max-w-[1400px] bg-white pb-10 sm:rounded-t-3xl">
        <div className="grid grid-cols-11 md:grid-cols-4">
          <NavLink
            to="#move-wizard"
            icon={<Wand className="h-8 w-8" />}
            label="Move progress"
            isActive={activeSection === 'move-wizard'}
            onClick={() => {
              const element = document.querySelector('#move-wizard');
              if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
              }
              setActiveSection('move-wizard');
            }}
          />
          <NavLink
            to="#my-services"
            icon={<Services className="h-8 w-8" />}
            label="Home Services"
            isActive={activeSection === 'my-services'}
            onClick={() => {
              const element = document.querySelector('#my-services');
              if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
              }
              setActiveSection('my-services');
            }}
          />
          <NavLink
            to="#home-team"
            icon={<Chat className="h-7 w-7" />}
            label="Get in touch"
            isActive={activeSection === 'home-team'}
            onClick={() => {
              const element = document.querySelector('#home-team');
              if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
              }
              setActiveSection('home-team');
            }}
          />
          <div
            className="col-span-2 flex cursor-pointer items-center justify-center gap-3 border-l border-gray-200 px-3 md:col-span-1"
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          >
            <ArrowTop className="h-14 fill-primary md:h-6" />
            <span className="hidden text-base font-medium md:block">Back to top</span>
          </div>
        </div>
      </nav>
    </div>
  );
};
