import { useEffect, useState } from 'react';

import { GET_ADDRESS_API_KEY } from '../constants';

function isValidUKPostcode(postcode: string) {
  const postcodeNoSpace = postcode.replace(/\s+/g, '').toUpperCase();
  const regex =
    /^(GIR ?0AA|[A-PR-UWYZ]([0-9]{1,2}|([A-HK-Y][0-9]{1,2}|[0-9][A-HJKSTUW]|[A-HK-Y][0-9][ABEHMNPRVWXY])) ?[0-9][ABD-HJLNP-UW-Z]{2})$/i;
  return regex.test(postcodeNoSpace);
}

export const useFetchAddressSuggestions = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (!searchTerm || searchTerm.replace(' ', '').length < 5) return;

      if (!isValidUKPostcode(searchTerm)) {
        setError({ message: 'Invalid postcode' });
        setSuggestions([]);
        return;
      } else {
        setError(null);
      }

      setIsFetching(true);

      const searchString = searchTerm.replace(/\s+/g, '');

      try {
        const response = await fetch(
          `https://api.getAddress.io/autocomplete/${searchString}?api-key=${GET_ADDRESS_API_KEY}`,
          {
            method: 'GET',
          }
        );

        const data = await response.json();

        if (data) {
          setSuggestions(
            data.suggestions.map((suggestion: any) => ({
              key: suggestion.address,
              value: suggestion.id,
            }))
          );
        }
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      } finally {
        setIsFetching(false);
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, setSuggestions]);

  return { setSearchTerm, isFetching, suggestions, error };
};
