import { useState, useEffect } from 'react';
import { renderToString } from 'react-dom/server';
import { useSelector } from 'react-redux';

import { BroadbandContent } from '@components/Homebox/BroadbandContent';
import { EnergyContent } from '@components/Homebox/EnergyContent';

import { selectProfile } from '@features/profile/profile';
import { MoveStatus, User } from '@features/profile/profile.types';

import { environmentFor } from '@utils/environment';

type HomeboxUser = {
  firstName?: string;
  lastName?: string;
  email?: string;
  address?: {
    addressLine1?: string;
    locality?: string;
    postcode?: string;
  };
};

const mapDataToHomeboxUser = (profile: User): HomeboxUser => {
  const {
    customer: {
      email,
      name: { firstName, lastName },
    },
    properties,
  } = profile;

  const address = properties[0];

  return {
    firstName: firstName || '',
    lastName: lastName || '',
    email: email || '',
    ...(address &&
      address.status === MoveStatus.TENANCY && {
        address: {
          addressLine1: address.addressLine1 || '',
          locality: address.city || '',
          postcode: address.postcode || '',
        },
      }),
  };
};

/**
 * useHomebox Hook
 * This hook acts as a wrapper for the Homebox drop-in widget.
 * It works by loading and appending the Homebox script to the DOM,
 * then rendering the widget once the script has loaded.
 *
 * You can find out more information in their documentation:
 * https://developers.homebox.co.uk/docs/api-docs/5d2b2bc6497d6-drop-in-widget
 */

export const useHomebox = ({ serviceType = 'energy' }: { serviceType?: string }) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const { value: profile } = useSelector(selectProfile);

  const ENVIRONMENT = environmentFor(window.location.href);

  useEffect(() => {
    const appendScriptToDocument = () => {
      const script = document.createElement('script');
      script.id = 'homebox';
      script.src = ['dev', 'local'].includes(ENVIRONMENT)
        ? 'https://comparison-sandbox.homebox.co.uk/js/homebox-v2.js'
        : 'https://comparison.homebox.co.uk/js/homebox-v2.js';
      script.async = true;
      script.defer = true;
      script.crossOrigin = 'anonymous';
      script.onload = () => setHasLoaded(true);
      document.body.append(script);
    };

    appendScriptToDocument();
  }, [ENVIRONMENT]);

  useEffect(() => {
    const user = !!profile ? mapDataToHomeboxUser(profile) : {};

    if (hasLoaded === true) {
      const options = {
        serviceType,
        comparisonType: 'full',
        partnerId: 'epCZ0ffkiYYod5sM',
        steps: {
          introText: renderToString(
            serviceType === 'energy' ? <EnergyContent /> : <BroadbandContent />
          ),
        },
        user,
      };

      // @ts-ignore
      Homebox.renderApp(options);

      setIsReady(true);
    }
  }, [hasLoaded, serviceType, profile]);

  return { isReady };
};
