type MoveReference = {
  id: string;
};

export type User = {
  customer: ICustomer;
  moves: MoveReference[];
  id?: string | undefined; // customer-id
  coadjuteParticipantId?: string;
  properties: Address[];
};

export type ProfileState = {
  value: User | null;
  isFetching: boolean;
  fetchComplete: boolean;
  errorOccurred: boolean;
  errorStatusCode?: number;
};

export enum TaskStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export enum MoveStatus {
  BUYER = 'BUYER',
  SELLER = 'SELLER',
  TENANCY = 'RENTER',
}

export type Task = {
  id: string;
  name: string;
  uiName: string;
  step: string;
  status: TaskStatus;
};

export type Address = {
  propertyId: string;
  addressLine1: string; // "1 The Road"
  addressLine2?: string; // "1 The Road"
  postcode: string; // "AB1 2CD"
  createdAt: string; // "2024-08-02T12:42:40.279522Z"
  updatedAt: string; // "2024-08-02T12:42:40.279522Z"
  city: string; // "London"
  country: string; // "GB"
  county?: string; // "London"
  status: MoveStatus; // "TENANCY" or "UNKNOWN"
  moveType: MoveStatus; // "TENANCY" or "UNKNOWN"
  startDate?: string; // "2024-08-02"
  endDate?: string; // "2025-08-02"
  tasks: Task[];
};
