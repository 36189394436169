import React, { forwardRef } from 'react';

interface TextFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  styles?: string;
  error?: any;
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ id, label, placeholder, required, styles, type = 'text', error, ...rest }, ref) => {
    return (
      <label htmlFor={id} className="relative flex flex-col gap-0.5">
        <span className="text-sm text-[#333333]">
          {label} {required && '*'}
        </span>
        <input
          id={id}
          name={id}
          type={type}
          placeholder={placeholder}
          required={required}
          ref={ref}
          {...rest}
          className={`h-[50px] rounded-lg border p-2 transition-all duration-300 focus:outline-none ${
            styles || ''
          } ${
            error
              ? 'border-red-500 focus:shadow-[0px_0px_0px_4px_#fecaca]'
              : 'border-[#BDBDBD] focus:border-[#2B303A] focus:shadow-[0px_0px_0px_4px_#EEE5E9]'
          }`}
        />
        {error && (
          <span className="absolute left-0 top-full py-1 text-sm text-red-500">
            {error.message}
          </span>
        )}
      </label>
    );
  }
);
